export function formatDateString(date: Date): string {
  const pad = (num: number): string => num.toString().padStart(2, '0');

  const day = pad(date.getDate());
  const month = pad(date.getMonth() + 1);
  const year = date.getFullYear();
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());

  return `${year}.${month}.${day}-${hours}h${minutes}`;
}

export function convertDateToNoTimezone(date: Date) {
  const offset = date.getTimezoneOffset() * 60000;
  const adjustedDate = new Date(date.getTime() - offset);
  return adjustedDate;
}

export function convertDateToFrenchFormat(dateString: string) {
  const [year, month, day] = dateString.split('-');
  return `${day}/${month}/${year}`;
}


export function parseFrenchDate(dateStr: string): string {
  if (!dateStr) {
    return '';
  }

  try {
    const parts = dateStr.split(' ');
    const day = parseInt(parts[1], 10);
    const year = parseInt(parts[3], 10);
    const hourMinute = parts[5].split('h');
    let hour = parseInt(hourMinute[0], 10);
    const minute = parseInt(hourMinute[1], 10);

    const months: { [key: string]: number } = {
      'janvier': 0, 'février': 1, 'mars': 2, 'avril': 3, 'mai': 4,
      'juin': 5, 'juillet': 6, 'août': 7, 'septembre': 8,
      'octobre': 9, 'novembre': 10, 'décembre': 11
    };
    const month = months[parts[2].toLowerCase()];
    const date = new Date(Date.UTC(year, month, day, hour, minute));
    const formattedDate = date.toISOString().substring(0, 16);

    return formattedDate;
  } catch (error) {
    console.log("Erreur d'analyse de la date");
    return '';
  }
}


export function formatDate(date: Date): string {
  const days = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
  const months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];

  const dayOfWeek = days[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');

  return `${dayOfWeek} ${dayOfMonth} ${month} ${year} à ${hour}h${minute}`;
}

export function formatDateFromString(date?: string): string {
  if (!date) {
    return 'Non spécifié';
  }
  return formatDate(new Date(date.substring(0, 16)));
}