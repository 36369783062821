import React, { useState } from 'react';
import generateInvoiceFrom from '../components/common/PdfTemplate';
import { Reservation } from '../components/reservations/Reservation';
import { useNavigate } from 'react-router-dom';

const GenerateInvoice: React.FC = () => {
    const navigate = useNavigate();
    const [pdfUrl, setPdfUrl] = useState<string | null>(null);

    const generatePdf = async () => {

        let productList = [
            {"title": "Alto TS415", "quantity": 2, "price": 50},
            {"title": "LD Systems ICOA 18 A", "quantity": 1, "price": 200},
            {"title": "JB Systems LIVE-6", "quantity": 1, "price": 100},
            {"title": "Micros Sans Fil JBL", "quantity": 1, "price": 150},
        ];
        

        // Example data to fill the PDF
        const reservation: Reservation = {
            id: '1',
            reservationNumber: 2780109882,
            pickupDate: new Date().toISOString(),
            returnDate: new Date().toISOString(),
            status: 'new',
            firstName: "Best",
            lastName: "Customer",
            email: "test@gmail.com",
            productTotal: 120,
            nip: 1020,
            country: "CH",
            phoneNumber: "",
            calculatedSum: 140,
            productList: JSON.stringify(productList),
            customerMessage: "",
            rentalCoefficient: 2,
            userId: "1",
            creationDate: new Date().toISOString(),
            modifiedDate: new Date().toISOString(),
            addDelivery: true,
            addPickup: true,
            deliveryAddress: "Lausanne",
            adjustment: true,
            installation: true,
            dismantling: true,
            installationFee: 10,
            adjustmentFee: 20,
            dismantlingFee: 30,
            onSite: true,
            onSiteFee: 40,
            deliveryFee: 50,
            pickupFee: 60,
            discount: true,
            discountAmount: 70,
          };
    
        // Generate the PDF blob using the fillPdfTemplate function
        const pdfBlob = await generateInvoiceFrom(reservation, true, "Best Customer,\n Avenue de la Victoire\n1000 Lausanne");
    
        // Create a URL for the generated PDF
        const url = URL.createObjectURL(pdfBlob);
        setPdfUrl(url);
      };

    return (
        <div className="container mx-auto p-4">

        
        <button
            onClick={() => navigate('/')}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded flex items-center">
            <svg className="mr-2 w-4 h-4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
            <path d="M15 19l-7-7 7-7"/>
            </svg>
            Retour
        </button>
        <br/>
        <br/>
        <button
            onClick={generatePdf}
            className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
        >
            Generate PDF
        </button>
        {pdfUrl && (
            <iframe
            src={pdfUrl}
            className="w-full h-screen mt-4 border"
            title="PDF Preview"
            />
        )}
        </div>
    );
}

export default GenerateInvoice;