import React from 'react';
import './styles/index.css';

import { AuthProvider } from './components/auth/AuthContext';
import HomePage from './pages/HomePage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReservationList from './pages/ReservationList';
import Protected from './components/auth/Protected';
import Navbar from './components/NavBar';
import ReturnReservation from './pages/ReturnReservation';
import ReservationBasicView from './pages/ReservationBasicView';
import GiveReservation from './pages/GiveReservation';
import { LoadingProvider } from './components/common/LoadingContext';
import NewReservation from './pages/NewReservation';
import { ReservationProvider } from './components/common/ReservationProvider';
import Scroller from './components/common/Scroller';
import DeliveryCost from './pages/DeliveryCost';
import CreateReservation from './pages/CreateReservation';
import TestPage from './pages/GenerateInvoice';

const App: React.FC = () => {

  return (
    <Router>
      <LoadingProvider>
        <AuthProvider>
          <ReservationProvider>
            <Scroller />
            <Navbar/>
            <Routes>
              <Route path="/" element={<HomePage />}/>
              <Route path="/all" element={<Protected><ReservationList/></Protected>}/>
              <Route path="/resa/:reservationId" element={<Protected><ReservationBasicView/></Protected>} />
              <Route path="/new/:reservationId" element={<Protected><NewReservation/></Protected>} />
              <Route path="/give/:reservationId" element={<Protected><GiveReservation/></Protected>} />
              <Route path="/facture/" element={<Protected><TestPage/></Protected>} />
              <Route path="/create/" element={<Protected><CreateReservation/></Protected>} />
              <Route path="/collect/:reservationId" element={<Protected><ReturnReservation/></Protected>}/>
              <Route path="/deliveryCost" element={<Protected><DeliveryCost/></Protected>}/>
            </Routes>
          </ReservationProvider>
        </AuthProvider>
      </LoadingProvider>
    </Router>
  );
};

export default App;
