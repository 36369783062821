import { useAuth } from './auth/AuthContext';
import { FiLogOut } from 'react-icons/fi';

const Navbar = () => {
  const { user, logOut } = useAuth();

  const handleSignOut = async () => {
    try {
      await logOut()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='flex justify-between bg-gray-200 w-full p-4 bg-purple-700'>
      <div className='flex items-center gap-2'>
        <h1 className='text-2xl font-bold text-white'>
          AirManager
        </h1>
      </div>
      {user?.displayName && (
        <button onClick={handleSignOut} className="text-xl text-white">
          <FiLogOut />
        </button>
      )}
    </div>
  );
};

export default Navbar;