import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const Protected = ({ children }: { children: React.ReactNode }) => {
    const { user } = useAuth();
    if (user) {
        return <>{children}</>;
    }

    return <Navigate to='/' />;
};

export default Protected;