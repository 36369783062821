import React, { useRef, useState, useEffect } from 'react';
import { httpsCallable } from 'firebase/functions';
import { useLoading } from "../components/common/LoadingContext"
import SignatureCanvas from 'react-signature-canvas';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../components/auth/AuthContext';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { doc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { db, functions } from '../components/auth/Config';
import { Reservation } from '../components/reservations/Reservation';
import { convertDateToNoTimezone, formatDateString } from '../components/common/Conversions';
import { Product } from '../components/common/ListBuilder';
import { MdCancel } from 'react-icons/md';
import { useReservation } from '../components/common/ReservationProvider';
import DeleteMenu from '../components/common/DeleteMenu';
import { FaCommentDots } from 'react-icons/fa';
import { Commentary } from '../components/reservations/Commentary';
import { generateContractEmail } from '../components/common/EmailTemplates';



const NewReservation: React.FC = () => {

  const navigate = useNavigate();
  const storage = getStorage();
  const { user } = useAuth();
  const { reservation } = useReservation();
  const { loading, setLoading, setLoadingMessage } = useLoading();
  const [file, setFile] = useState<File | null>(null);
  const [firstName, setFirstName] = useState('');
  const [returnDate, setReturnDate] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [dDayNote, setDDayNote] = useState<string>('');
  const [addPickup, setAddPickup] = useState<boolean>(false);
  const [pickupAddress, setPickupAddress] = useState<string>('');
  const [depositAmount, setDepositAmount] = useState<number>(0);
  const [paymentTotal, setPaymentTotal] = useState<number>(0);
  const [phoneNumber, setPhoneNumber] = useState('');
  const sigCanvasRef = useRef<SignatureCanvas | null>(null);
  const [productList, setProductList] = useState<Product[]>([]);
  const [newProductTitle, setNewProductTitle] = useState('');
  const [newProductQuantity, setNewProductQuantity] = useState(1);
  const [isMaterialChecked, setIsMaterialChecked] = useState(false);
  const [isSendMailChecked, setIsSendMailChecked] = useState(true);
  const [currentReservation, setCurrentReservation] = useState<Reservation | null>(null);



  const [showComments, setShowCommentsChecked] = useState(false);

  const [comment, setComment] = useState('');
  const [comments, setComments] = useState<Commentary[]>([]);


  useEffect(() => {
    if (reservation) {
      const reservationCopy: Reservation = { ...reservation };
      setCurrentReservation(reservationCopy);
      setFirstName(reservation.firstName);
      setLastName(reservation.lastName);
      setEmail(reservation.email);
      setAddPickup(reservation.addPickup || false);

      setPickupAddress(reservation.pickupAddress || "");
      setDDayNote(reservation.dDayNote ||'');
      setReturnDate(reservation.returnDate.substring(0, 16));
      setPhoneNumber(reservation.phoneNumber);
      setDepositAmount(reservation.depositAmount || 0);
      setPaymentTotal(reservation.calculatedSum || reservation.productTotal || 0);
      if (reservation?.productList) {
        try {
          const parsedProductList: Product[] = JSON.parse(reservation.productList);
          setProductList(parsedProductList);
        } catch (error) {
          console.error("Parsing error:", error);
          setProductList([]);
        }
      }
      if (reservation?.comments) {
        setComments(reservation.comments);
      }
    }
    
  }, [reservation]);


  const handleCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleAddPickupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddPickup(event.target.checked);
  };

  const handleAddProduct = () => {
    const newProduct: Product = {
      title: newProductTitle,
      quantity: newProductQuantity,
      price: 0
    };
    setProductList([...productList, newProduct]);
    setNewProductTitle('');
    setNewProductQuantity(1);
  };
  
  const handleProductNameChange = (index: number, title: string) => {
    const updatedList = productList.map((item, idx) => idx === index ? { ...item, title } : item);
    setProductList(updatedList);
  };

  const handleQuantityChange = (index: number, quantity: number) => {
    const updatedList = productList.map((item, idx) => idx === index ? { ...item, quantity } : item);
    setProductList(updatedList);
  };

  const handleRemoveProduct = (index: number) => {
    const newList = productList.filter((_, i) => i !== index);
    setProductList(newList);
  };

  const modifyPdfWithProductList = async () => {

    if (returnDate === '') {
      throw new Error("Veuillez saisir une date de retour");
    }

    // Charger le PDF existant
    const pdfUrl = `${process.env.PUBLIC_URL}/pdfs/liste_materiel.pdf`;
    const arrayBuffer = await fetch(pdfUrl).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(arrayBuffer);
    
    const page = pdfDoc.getPage(0);
  
    // Charger une police standard
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  
    let yPosition = page.getHeight() - 190; // Commencer en haut de la page
  
    // Parcourir la liste des produits et les ajouter au PDF
    productList.filter((e) => e.quantity > 0).forEach(product => {
      const text = `${product.title}, Qté : ${product.quantity}`;
      page.drawText(text, {
        x: 80,
        y: yPosition,
        size: 9,
        font: font,
      });
      yPosition -= 10; 
    });

    // Formatez la date d'aujourd'hui
    const today = new Date();
    const dateString = `${today.getDate()} ${today.toLocaleString('fr-FR', { month: 'long' })} ${today.getFullYear()}`;

    // Ajoutez la date au PDF
    page.drawText(dateString, {
      x: 410,
      y: page.getHeight() - 110, // Ajuste pour positionner en bas
      size: 10,
      font: font,
      color: rgb(0, 0, 0), // Texte noir
    });

    page.drawText(dateString, {
      x: 240,
      y: page.getHeight() - 150, // Ajuste pour positionner en bas
      size: 10,
      font: font,
      color: rgb(0, 0, 0), // Texte noir
    });

    const rDate = new Date(returnDate);
    const returnDateString = `${rDate.getDate()} ${rDate.toLocaleString('fr-FR', { month: 'long' })} ${rDate.getFullYear()}`;

    page.drawText(returnDateString, {
      x: 430,
      y: page.getHeight() - 150, // Ajuste pour positionner en bas
      size: 10,
      font: font,
      color: rgb(0, 0, 0), // Texte noir
    });

    const signatureCanvas = sigCanvasRef.current;
    let dataUrl = signatureCanvas ? signatureCanvas.getTrimmedCanvas().toDataURL('image/png') : null;
    
    // Assurez-vous que la signature a bien été capturée
    if (dataUrl) {
      const signatureImage = await pdfDoc.embedPng(dataUrl);

      page.drawImage(signatureImage, {
        x: 420, // Ajustez selon l'emplacement souhaité
        y: 42,
        width: 100, // Ajustez la taille selon vos besoins
        height: 50,
      });
    }
  
    // Sauvegarder le PDF modifié
    const pdfBytes = await pdfDoc.save();

    return pdfBytes;
  }

  const createContractPdf = async () => {

    // Étape 1: Charger le PDF depuis le dossier public
    const pdfUrl = `${process.env.PUBLIC_URL}/pdfs/extrait_conditions_generales.pdf`;
    const arrayBuffer = await fetch(pdfUrl).then(res => res.arrayBuffer());

    // Étape 2: Ajouter la signature au PDF
    const pdfDoc = await PDFDocument.load(arrayBuffer);
    
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

    const page = pdfDoc.getPage(0); // Ajoutez la signature à la première page

    // Formatez la date d'aujourd'hui
    const today = new Date();
    const dateString = `${today.getDate()} ${today.toLocaleString('fr-FR', { month: 'long' })} ${today.getFullYear()}`;

    // Ajoutez la date au PDF
    page.drawText(dateString, {
      x: 410,
      y: page.getHeight() - 153, // Ajuste pour positionner en bas
      size: 10,
      font: font,
      color: rgb(0, 0, 0), // Texte noir
    });

    const signatureCanvas = sigCanvasRef.current;
    let dataUrl = signatureCanvas ? signatureCanvas.getTrimmedCanvas().toDataURL('image/png') : null;
    
    // Assurez-vous que la signature a bien été capturée
    if (dataUrl) {
      const signatureImage = await pdfDoc.embedPng(dataUrl);

      page.drawImage(signatureImage, {
        x: 420, // Ajustez selon l'emplacement souhaité
        y: 42,
        width: 100, // Ajustez la taille selon vos besoins
        height: 50,
      });
    }

    const pdfBytes = await pdfDoc.save();

    return pdfBytes;
  }

  const addComment = async () => {
    if (!comment.trim()) return;
    if (!reservation) return;

    setLoading(true, "Ajout");

    const newComment = {
      id: `comment${comments.length + 1}`,
      text: comment,
      timestamp: new Date().toISOString(),
    };

    const updatedComments = [...comments, newComment];
    setComments(updatedComments);
    setComment('');
    try {
      await updateDoc(doc(db, 'reservations', reservation.id), {
        comments: updatedComments,
      });
    } catch (error) {
      console.error('Erreur lors de l\'ajout du commentaire', error);
      alert('Erreur lors de l\'ajout du commentaire: \n\n' + error);
    }

    setLoading(false);
  };

  const handleStatusChange = async (status: string) => {

    if (!currentReservation) {
      console.error("currentReservation est nulle");
      alert('Erreur de donnée, recharger la page');
      return;
    }

    setLoading(true, "Enregistrement");

    try {
      const reservationRef = doc(db, "reservations", currentReservation.id);
      await updateDoc(reservationRef, {
        status: status,
        modifiedDate: new Date().toISOString(),
      });
    } catch (error) {
      console.error('Erreur lors de l\'ajout de la réservation', error);
      alert('Erreur lors de l\'ajout de la réservation: \n\n' + error);
      setLoading(false);
    }

    setLoading(false);

    navigate('/');
  
  };

  const handleSaveOnly = async () => {
    if (!user) {
      console.error("User est nul");
      alert('Vous n\'êtes plus connecté');
      return;
    }
  
    if (!currentReservation) {
      console.error("ConfirmedReservation est null");
      alert('Erreur de transfer de donnée, recharger la page');
      return;
    }
  
    if (new Date() >= new Date(returnDate)) {
      alert('La date de prise en charge doit être avant la date de retour');
      return;
    }
  
    setLoading(true, "Enregistrement");
  
    const updatedReservation = {
      ...currentReservation,
      productList: JSON.stringify(productList),
      returnDate,
      firstName,
      lastName,
      email,
      phoneNumber,
      depositAmount,
      calculatedSum: paymentTotal,
      dDayNote,
      addPickup,
      pickupAddress,
      modifiedDate: new Date().toISOString(),
    };
  
    try {
      const updateRef = doc(db, "reservations", currentReservation.id);
      await updateDoc(updateRef, updatedReservation);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la réservation', error);
      alert('Erreur lors de la mise à jour de la réservation: \n\n' + error);
    } finally {
      setLoading(false);
    }
  };
  

  const giveReservation = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!user) {
      console.error("User est nul");
      alert('Vous n\'êtes plus connecté');
      return;
    }

    if (!file) {
      console.error("file est nul");
      alert('Importer la photo du document d\' identité');
      return;
    }

    if (!sigCanvasRef.current) {
      console.error("sigCanvasRef.current est nul");
      alert('Signature manquante');
      return;
    }

    if (!currentReservation) {
      console.error("currentReservation est nulle");
      alert('Erreur de donnée, recharger la page');
      return;
    }

    setLoading(true, "Enregistrement");

    const formData = new FormData(event.currentTarget);

    //Construct the document path
    const filePath = `${formatDateString(convertDateToNoTimezone(new Date()))}_R${currentReservation.reservationNumber}`;

    //Construction of the currentReservation
    currentReservation.pickupDate = convertDateToNoTimezone(new Date()).toISOString().substring(0, 16);
    currentReservation.returnDate = returnDate;
    currentReservation.status = "out";
    currentReservation.firstName = firstName;
    currentReservation.lastName = lastName;
    currentReservation.email = email;
    currentReservation.addPickup = addPickup;
    currentReservation.pickupAddress = pickupAddress;
    currentReservation.phoneNumber = phoneNumber;
    currentReservation.depositMethod = formData.get('depositMethod') as string;

    if (currentReservation.depositMethod === "no") {
      currentReservation.depositAmount = 0;
    } else {
      currentReservation.depositAmount = depositAmount;
    }

    currentReservation.paymentMethod = formData.get('paymentMethod') as string;
    currentReservation.paymentAmount = paymentTotal;
    currentReservation.documentURL = 'reservations/' + filePath;
    currentReservation.knownFrom = formData.get('knowFrom') as string;
    currentReservation.dDayNote = dDayNote;
    currentReservation.productList = JSON.stringify(productList);
    currentReservation.modifiedDate = new Date().toISOString();

    //Prepare the photo Upload
    const photoUrl = 'reservations/' + filePath + `/${(file as File).name}`;
    const storagePhotoRef = ref(storage, photoUrl);

    const productListPdfBytes = await modifyPdfWithProductList();
    if (!productListPdfBytes) {
      alert("Erreur dans la génération de la liste du matériel, faites signer physiquement un contrat");
    };

    const contractPdfBytes = await createContractPdf();
    if (!contractPdfBytes) {
      alert("Erreur dans la génération du contrat, faites signer physiquement un contrat");
    };

    //Envoyer les PDF modifiés sur Firebase Storage
    let path = 'reservations/' + filePath + `/liste_materiel_signed.pdf`;
    const pdfBlobProductList = new Blob([productListPdfBytes], { type: 'application/pdf' });
    const PDFRefProductList = ref(storage, path);

    path = 'reservations/' + filePath + `/extrait_conditions_generales_signed.pdf`
    const pdfBlobContract = new Blob([contractPdfBytes], { type: 'application/pdf' });
    const PDFRefContract = ref(storage, path);

    setLoadingMessage("Enregistrement");
    
    try {

      await uploadBytes(PDFRefProductList, pdfBlobProductList);
      console.log('Product List Pdf uploaded')

      await uploadBytes(PDFRefContract, pdfBlobContract);
      console.log('Contract Pdf uploaded')

      await uploadBytes(storagePhotoRef, file);
      console.log('ID uploaded');

  
        
    } catch (error) {

      console.error('Erreur lors de l\'ajout des uploads', error);
      alert('Erreur lors de l\'ajout des uploads: \n\n' + error);
      setLoading(false);
      return;

    }

    const downloadURLProductList = await getDownloadURL(PDFRefProductList);
    currentReservation.productListLink = downloadURLProductList;
    console.log('Product List PDF Download URL:', downloadURLProductList);

    const downloadURLContract = await getDownloadURL(PDFRefContract);
    currentReservation.contractLink = downloadURLContract;
    console.log('Contract PDF Download URL:', downloadURLContract);


    try {

      const updateRef = doc(db, "reservations", currentReservation.id);
      await updateDoc(updateRef, {
        ...currentReservation
      });
        
    } catch (error) {

      console.error('Erreur lors de l\'ajout de la réservation', error);
      alert('Erreur lors de l\'ajout de la réservation: \n\n' + error);
      setLoading(false);

    }

    if (isSendMailChecked) {

      setLoadingMessage("Envoie des PDFs");
      try {

        const sendEmail = httpsCallable(functions, "d04af80e4b9d810535949bc3936089caa8655cb17045d78d5d8aa6db");
  
        const emailToSend = currentReservation.email;
        
        const htmlContent = generateContractEmail(currentReservation);

        await sendEmail({
          to: emailToSend,
          subject: "Votre location a débuté | AirSound",
          html: htmlContent
        });
      
        setLoading(false);
        navigate('/');
      
      } catch (error) {
        console.error('Erreur lors de l\'envoi de l\'email', error);
        alert('Erreur lors de l\'envoi de l\'email: \n\n' + error);
        setLoading(false);
      }
    }
  };

  const clear = () => {
    if (sigCanvasRef.current) {
      sigCanvasRef.current.clear();
    }
  };

  return (
    <div className="container mx-auto p-4">

      <button
        onClick={() => navigate('/')}
        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded flex items-center"
      >
        <svg className="mr-2 w-4 h-4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
          <path d="M15 19l-7-7 7-7"/>
        </svg>
        Retour
      </button>

      <br/>
      <h1 className="text-xl font-bold my-4 flex justify-center">REMISE</h1>
      <br/>

      <div className="mb-4">
        <label>
          <input
            type="checkbox"
            className='accent-purple-500'
            name="showcomments"
            checked={showComments}
            onChange={(e) => setShowCommentsChecked(e.target.checked)}
          />
          Afficher les notes internes
        </label>
      </div>

      <div>
        
          <div className="bg-white shadow-md rounded-lg p-6 mb-6">
            <h2 className="text-xl font-bold mb-4">Réservation #{reservation?.reservationNumber}</h2>
            <div className="grid grid-cols-1 gap-4">
              {reservation?.customerMessage && (
                <div className="flex items-start">
                  <FaCommentDots className="text-gray-500 mt-1 mr-2" />
                  <div>
                    <strong>Message du client :</strong>
                    <div className="mt-2">{reservation.customerMessage}</div>
                  </div>
                </div>
              )}     


              {showComments ? (
              <div>
                <div className="flex items-start">
                  <FaCommentDots className="text-gray-500 mt-1 mr-2" />
                  <div>
                    <strong>Notes internes :</strong>
                  </div>
                </div>
                {comments.map((comment, index) => (
                  <div key={index} className="p-2 mt-2 bg-gray-100 rounded">
                    {comment.text}
                  </div>
                ))}
              </div>
              ) : null}
              
              {reservation?.note && (
                <div className="flex items-start">
                  <FaCommentDots className="text-gray-500 mt-1 mr-2" />
                  <div>
                    <strong>Note pour le jour J :</strong>
                    <div className="mt-2">{reservation.note}</div>
                  </div>
                </div>
              )}
            </div>
            {showComments ? (
              <div>
                <input
                  type="text"
                  value={comment}
                  placeholder='Ajouter une note interne'
                  onChange={(e) => setComment(e.target.value)}
                  className="border rounded p-2 w-full mt-4"
                />

                <div className="flex justify-end">
                  <button onClick={addComment} className="mt-2 bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">
                    Ajouter
                  </button>
                </div>

              </div>
            ) : null}

          </div>
      </div>

      <form onSubmit={giveReservation} className="mb-4">

        <br/>
        {/*Valider les informations*/}
        <h2 className="text-xl font-bold my-4">{`1) Valider les informations`}</h2>


        <div className="flex-1">
          <label htmlFor="firstname" className="block text-gray-700 text-sm font-bold mb-2">
            Prénom
          </label>
          <input
            type="text"
            name="firstname"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <br/>

        <div className="flex-1">
          <label htmlFor="lastname" className="block text-gray-700 text-sm font-bold mb-2">
            Nom
          </label>
          <input
            type="text"
            name="lastname"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <br/>

        <div className="flex-1">
          <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
            Email
          </label>
          <input
            type="text"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <br/>

        <div className="flex-1">
          <label htmlFor="phoneNumber" className="block text-gray-700 text-sm font-bold mb-2">
            Téléphone
          </label>
          <input
            type="text"
            name="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>


        <br/>
        {/*Mesure de notre impact*/}
        <h2 className="text-xl font-bold my-4">{`2) Mesure de notre impact`}</h2>

        <fieldset className="flex flex-col gap-4">
          <legend className="text-gray-700 text-sm font-bold mb-2">
            Comment nous avez-vous connu ?
          </legend>
          <div className="flex flex-col sm:flex-row gap-4">
            <label className="flex items-center gap-2">
              <input type="radio" name="knowFrom" className='accent-purple-500' value="internet" required />
              Internet
            </label>
            <label className="flex items-center gap-2">
              <input type="radio" name="knowFrom" className='accent-purple-500' value="reseaux sociaux" />
              Réseaux sociaux
            </label>
            <label className="flex items-center gap-2">
              <input type="radio" name="knowFrom" className='accent-purple-500' value="amis" />
              Amis
            </label>
            <div className="flex flex-col sm:flex-row gap-2">
              <label className="flex items-center gap-2">
                <input type="radio" name="knowFrom" className='accent-purple-500' value="autre" />
                Autre
              </label>
              <input type="text" name="knowFromAutre" className="shadow appearance-none border rounded py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Préciser" />
            </div>
          </div>
        </fieldset>


        <br/>
        {/*Controler le materiel*/}
        <h2 className="text-xl font-bold my-4">{`3) Contrôler le matériel`}</h2>

        {productList.map((product, index) => (
          <div key={index} className="flex flex-wrap -mx-3 mb-4 items-center">
            <div className="w-full sm:w-7/12 px-3">
              <input
                type="text"
                value={product.title}
                onChange={(e) => handleProductNameChange(index, e.target.value)}
                disabled={isMaterialChecked}
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="w-4/12 sm:w-3/12 px-3">
              <input
                type="number"
                min="0"
                value={product.quantity}
                disabled={isMaterialChecked}
                onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="w-1/12 px-3 flex justify-center">
              <button
                onClick={() => handleRemoveProduct(index)}
                type="button"
                className={`${
                  isMaterialChecked ? 'bg-gray-500 hover:bg-gray-500 cursor-not-allowed' : 'bg-red-500 hover:bg-red-700'
                } text-white font-bold py-2 px-4 rounded`}
                disabled={isMaterialChecked}
              >
                <MdCancel />
              </button>
            </div>
          </div>
        ))}

        <div className="flex flex-wrap -mx-3 mb-4 items-center">
          <div className="w-full sm:w-7/12 px-3">
            <input
              type="text"
              placeholder="Ajouter un produit"
              value={newProductTitle}
              disabled={isMaterialChecked}
              onChange={(e) => setNewProductTitle(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="w-4/12 sm:w-3/12 px-3">
            <input
              type="number"
              min="0"
              placeholder="Quantité"
              value={newProductQuantity}
              disabled={isMaterialChecked}
              onChange={(e) => setNewProductQuantity(parseInt(e.target.value))}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="w-1/12 px-3 flex justify-center">
            <button
              onClick={handleAddProduct}
              type="button"
              className={`${
                isMaterialChecked ? 'bg-gray-500 hover:bg-gray-500 cursor-not-allowed' : 'bg-purple-500 hover:bg-purple-700'
              } text-white font-bold py-2 px-4 rounded`}
              disabled={isMaterialChecked}
            >
              +
            </button>
          </div>
        </div>


        <div className="mb-4">
          <label>
            <input
              type="checkbox"
              className='accent-purple-500'
              required
              name="checkMaterielFait"
              checked={isMaterialChecked}
              onChange={(e) => setIsMaterialChecked(e.target.checked)}
            />
            Matériel vérifié
          </label>
        </div>

        <br/>
        {/*Planificateur de retour*/}
        <h2 className="text-xl font-bold my-4">{`4) Planification du retour`}</h2>
        <div className="mb-4">
          <label htmlFor="dismantling" className="flex items-center">
            <input
              type="checkbox"
              checked={addPickup}
              id="dismantling"
              onChange={handleAddPickupChange}
              className="mr-2"
            />
            Reprise demandée 
          </label>
          <br/>
          <input
            type="text"
            placeholder="Spécifier l'adresse de reprise"
            disabled={!addPickup}
            value={pickupAddress}
            onChange={(e) => setPickupAddress(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <legend className="block text-gray-700 text-sm font-bold mb-2">
          Date et heure
        </legend>
        <input
          type="datetime-local"
          name="returnDateAndHour"
          required
          value={returnDate}
          onChange={(e) => setReturnDate(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        <br/>
        <br/>
        <br/>
        {/*Recevoir la CAUTION*/}
        <h2 className="text-xl font-bold my-4">{`5) Recevoir la caution`}</h2>

        <fieldset className="mb-4 flex flex-col gap-4">
          <legend className="block text-gray-700 text-sm font-bold mb-2">
            Méthode de dépôt
          </legend>
          <div className="flex flex-col sm:flex-row gap-4">
            <label className="flex items-center gap-2">
              <input type="radio" name="depositMethod" className='accent-purple-500' value="Twint" required />
              Twint
            </label>
            <label className="flex items-center gap-2">
              <input type="radio" name="depositMethod" className='accent-purple-500' value="Cash" />
              Cash
            </label>
            <label className="flex items-center gap-2">
              <input type="radio" name="depositMethod" className='accent-purple-500' value="no" />
              Sans caution
            </label>
          </div>
        </fieldset>


        <div className="mb-4">
          <input type="number"
          name="depositAmount"
          id="depositAmount"
          min="0"
          value={depositAmount}
          onChange={(e) => setDepositAmount(parseInt(e.target.value))}
          className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ml-2"
          placeholder="Montant de la caution" />
        </div>


        {/*Recevoir le PAIEMENT*/}
        <br/>
        <h2 className="text-xl font-bold my-4">{`6) Recevoir le paiement`}</h2>

        <fieldset className="mb-4 flex flex-col gap-4">
          <legend className="block text-gray-700 text-sm font-bold mb-2">
            Méthode de paiment
          </legend>
          <div className="flex flex-col sm:flex-row gap-4">
            <label className="flex items-center gap-2">
              <input type="radio" name="paymentMethod" className='accent-purple-500' value="Twint" required />
              Twint
            </label>
            <label className="flex items-center gap-2">
              <input type="radio" name="paymentMethod" className='accent-purple-500' value="Cash" />
              Cash
            </label>
            <label className="flex items-center gap-2">
              <input type="radio" name="paymentMethod" className='accent-purple-500' value="Facture" />
              Facture
            </label>
          </div>
        </fieldset>

        <div className="mb-4">
          <input type="number"
          name="paymentAmount"
          id="paymentAmount"
          min="0"
          value={paymentTotal}
          onChange={(e) => setPaymentTotal(parseInt(e.target.value))}
          className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ml-2"
          placeholder="Montant de la location" />
        </div>

        <br/>
        {/*Document d'identité*/}
        <h2 className="text-xl font-bold my-4">{`7) Copie du document d'identité`}</h2>

        <legend className="block text-gray-700 text-sm font-bold mb-2">
          Prendre une photo
        </legend>
        <input type="file" accept="image/*" capture="environment" onChange={handleCapture}/>
        <br/>
        <br/>
        <legend className="block text-gray-700 text-sm font-bold mb-2">
          Depuis la galerie
        </legend>
        <input type="file" accept="image/*" onChange={handleCapture}/>

        <br/>
        <br/>
        {/*Signature du locataire*/}
        <h2 className="text-xl font-bold my-4">{`8) Signature du locataire`}</h2>

        <div className="mx-auto max-w-[500px] w-full border-2 p-1">
          <SignatureCanvas
            ref={sigCanvasRef}
            penColor='black'
            canvasProps={{
              className: 'signatureCanvas w-full h-[200px]',
              style: { backgroundColor: 'white' } 
            }}
            clearOnResize={false}
          />
        </div>
        <br/>
        
        <button 
          type="button"
          onClick={clear}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
          Effacer
        </button>
        <br/>
        <br/>
        <h2 className="text-xl font-bold my-4">Note particulière</h2>
        <div className="mb-4">
          <label htmlFor="note" className="block text-gray-700 text-sm font-bold mb-2">
            Note
          </label>
          <textarea name="note"
          rows={3}
          value={dDayNote}
          onChange={(e) => setDDayNote(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"></textarea>
        </div>
        <br/>
        <div className="flex justify-end">
          <div className="mb-4">
            <label>
              <input
                type="checkbox"
                className='accent-purple-500'
                name="checkMail"
                checked={isSendMailChecked}
                onChange={(e) => setIsSendMailChecked(e.target.checked)}
              />
              Envoyer les documents au locataire
            </label>
          </div>
        </div>
        <div className="flex justify-end">
          <button type="submit" className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">
            Valider la remise
          </button>
        </div>
        <br/>
        <br/>
        <div className="flex justify-end">
          <button type="button" onClick={handleSaveOnly} disabled={loading} className="bg-pink-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">
            Sauvegarder uniquement
          </button>
        </div>
        <br/>
      </form>
      
      <DeleteMenu reservationStatus={reservation?.status} onStatusChange={handleStatusChange} />
      
    </div>
  );  
};

export default NewReservation;
