// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";


const fbc = {
  apiKey: "AIzaSyBxl5pxYpv0CqwQ-lQpICxZ7yHKXwebu3k",
  authDomain: "airsound-manager.firebaseapp.com",
  projectId: "airsound-manager",
  storageBucket: "airsound-manager.appspot.com",
  messagingSenderId: "139137038847",
  appId: "1:139137038847:web:967cafae5f3f8c7b624fca",
};


// Initialize Firebase
const app = initializeApp(fbc);
const auth = getAuth(app);
const functions = getFunctions(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LepSHApAAAAAMhjuCDx3JhugQy__z1mmxlk6szO"),
  isTokenAutoRefreshEnabled: true 
});


export {auth, provider, db, functions};