import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReservation } from '../components/common/ReservationProvider';
import { useLoading } from "../components/common/LoadingContext"
import { Product } from '../components/common/ListBuilder';
import { doc, updateDoc } from 'firebase/firestore';
import { db, functions } from '../components/auth/Config';
import DeleteMenu from '../components/common/DeleteMenu';
import { FaCar, FaMoneyBillWave, FaRegCalendarCheck, FaInfoCircle, FaUser, FaEnvelope, FaPhone, FaCommentDots} from 'react-icons/fa';
import { formatDateFromString } from '../components/common/Conversions';
import { FaTruck, FaTools, FaClock, FaBuilding, FaTag} from 'react-icons/fa';
import DownloadButton from '../components/common/DownloadButton';



//import { FiDownload } from 'react-icons/fi';

const OngoingReservation: React.FC = () => {
  const navigate = useNavigate();

  const { reservation } = useReservation();
  const [productList, setProductList] = useState<Product[]>([]);
  const { setLoading, setLoadingMessage } = useLoading();


  useEffect(() => {
    if (reservation) {
      if (reservation?.productList) {
        try {
          const parsedProductList: Product[] = JSON.parse(reservation.productList);
          setProductList(parsedProductList);
        } catch (error) {
          console.error("Parsing error:", error);
          setProductList([]);
        }
      }
    }
    
  }, [reservation]);

  const handleStatusChange = async (status: string) => {

    if (!reservation) {
      console.error("currentReservation est nulle");
      alert('Erreur de donnée, recharger la page');
      return;
    }

    setLoading(true, "Enregistrement");

    try {
      const reservationRef = doc(db, "reservations", reservation.id);
      await updateDoc(reservationRef, {
        status: status,
        modifiedDate: new Date().toISOString(),
      });
    } catch (error) {
      console.error('Erreur lors de l\'ajout de la réservation', error);
      alert('Erreur lors de l\'ajout de la réservation: \n\n' + error);
      setLoading(false);
    }

    setLoading(false);

    navigate('/');
  
  };

  return (
    <div className="container mx-auto p-4">

      <button
        onClick={() => navigate('/')}
        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded flex items-center"
      >
        <svg className="mr-2 w-4 h-4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
          <path d="M15 19l-7-7 7-7"/>
        </svg>
        Retour
      </button>

      <br/>
      <h1 className="text-xl font-bold my-4 flex justify-center">CONSULTATION</h1>


      <form className="mb-4">

        <br/>
        {/*informations*/}
        <h2 className="text-xl font-bold my-4">{`1) Informations client`}</h2>
  

        <br/>

        <div className="bg-white shadow-md rounded-lg p-6 mb-6">
          <h2 className="text-xl font-bold mb-4">Réservation #{reservation?.reservationNumber}</h2>

          <div className="grid grid-cols-1 gap-4">
            <div className="flex items-center">
              <FaUser className="text-gray-500 mr-2" />
              <div>
                <strong>Prénom :</strong> <span>{reservation?.firstName}</span>
              </div>
            </div>
            <div className="flex items-center">
              <FaUser className="text-gray-500 mr-2" />
              <div>
                <strong>Nom :</strong> <span>{reservation?.lastName}</span>
              </div>
            </div>
            <div className="flex items-center">
              <FaEnvelope className="text-gray-500 mr-2" />
              <div>
                <strong>Email :</strong> <span>{reservation?.email}</span>
              </div>
            </div>
            <div className="flex items-center">
              <FaPhone className="text-gray-500 mr-2" />
              <div>
                <strong>Téléphone :</strong> <span>{reservation?.phoneNumber}</span>
              </div>
            </div>
            <div className="flex items-start">
              <FaCommentDots className="text-gray-500 mt-1 mr-2" />
              <div>
                <strong>Message du client :</strong>
                <div className="mt-2">{reservation?.customerMessage || ''}</div>
              </div>
            </div>
          </div>
        </div>


        <br/>
        {/*Controler le materiel*/}
        <h2 className="text-xl font-bold my-4">{`2) Matériel loué`}</h2>

        {productList.map((product, index) => (
          <div key={index} className="flex flex-wrap -mx-3 mb-4 items-center">
            <div className="w-full sm:w-7/12 px-3">
              <input
                type="text"
                value={product.title}
                disabled
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="w-4/12 sm:w-3/12 px-3">
              <input
                type="number"
                min="0"
                value={product.quantity}
                disabled
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
          </div>
        ))}

<br/>
        {/*Information sur la location*/}
        <h2 className="text-xl font-bold my-4">{`3) Informations de location`}</h2>


        <div className="bg-white shadow-md rounded-lg p-6 mb-6">
          <h2 className="text-xl font-bold mb-4 flex items-center"><FaRegCalendarCheck className="mr-2" />Planification</h2>
          <div className="grid grid-cols-2 gap-4 mb-6">
            <div>
              <div className="text-gray-700 font-bold">Départ</div>
              <div className="flex items-center bg-gray-100 p-3 rounded mt-1">
                <FaCar className="text-gray-500 mr-2" />
                <span>{formatDateFromString(reservation?.pickupDate) || 'Non spécifié'}</span>
              </div>
            </div>
            <div>
              <div className="text-gray-700 font-bold">Retour</div>
              <div className="flex items-center bg-gray-100 p-3 rounded mt-1">
                <FaCar className="text-gray-500 mr-2" />
                <span>{formatDateFromString(reservation?.returnDate) || 'Non spécifié'}</span>
              </div>
            </div>
          </div>

          <h2 className="text-xl font-bold mb-4 flex items-center"><FaMoneyBillWave className="mr-2" />Finances</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <div className="text-gray-700 font-bold">Caution déposée</div>
              <div className="bg-gray-100 p-3 rounded mt-1">
                <div>Méthode : {reservation?.depositMethod || 'Non spécifié'}</div>
                <div>Montant : {reservation?.depositAmount ? `${reservation?.depositAmount} CHF` : 'Non spécifié'}</div>
              </div>
            </div>
            <div>
              <div className="text-gray-700 font-bold">Caution retournée</div>
              <div className="bg-gray-100 p-3 rounded mt-1">
                <div>Méthode : {reservation?.depositReturnMethod || 'Non spécifié'}</div>
                <div>Montant : {reservation?.depositReturnAmount ? `${reservation?.depositReturnAmount} CHF` : 'Non spécifié'}</div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 mb-4">
            <div>
              <div className="text-gray-700 font-bold">Paiement</div>
              <div className="bg-gray-100 p-3 rounded mt-1">
                <div>Méthode : {reservation?.paymentMethod || 'Non spécifié'}</div>
                <div>Montant : <strong>{reservation?.paymentAmount ? `${reservation?.paymentAmount} CHF` : reservation?.productTotal ? `${reservation?.productTotal} CHF` : 'Non spécifié'}</strong></div>
              </div>
            </div>
          </div>
          
          <h2 className="text-xl font-bold mb-4 flex items-center"><FaInfoCircle className="mr-2" />Impact</h2>
          <div className="bg-gray-100 p-3 rounded mb-6">
            Découverte : {reservation?.knownFrom || 'Non spécifié'}
          </div>
        </div>
        <br/>

        <h2 className="text-xl font-bold my-4">{`5) Options de location`}</h2>
        <div className="bg-white shadow-md rounded-lg p-6 mb-6">

          {reservation?.addDelivery && (
            <div className="mb-4">
              <div className="flex items-center">
                <FaTruck className="text-gray-500 mr-2" />
                <div>
                  <strong>Livraison:</strong>
                  <div>Adresse: {reservation?.deliveryAddress}</div>
                  <div>Tarif: {reservation?.deliveryFee ? `${reservation?.deliveryFee} CHF` : 'Non spécifié'}</div>
                </div>
              </div>
            </div>
          )}

          {reservation?.installation && (
            <div className="mb-4 flex items-center">
              <FaTools className="text-gray-500 mr-2" />
              <strong>Installation:</strong> {reservation?.installationFee ? `${reservation?.installationFee} CHF` : 'Non spécifié'}
            </div>
          )}

          {reservation?.adjustment && (
            <div className="mb-4 flex items-center">
              <FaTools className="text-gray-500 mr-2" />
              <strong>Réglage:</strong> {reservation?.adjustmentFee ? `${reservation?.adjustmentFee} CHF` : 'Non spécifié'}
            </div>
          )}

          {reservation?.onSite && (
            <div className="mb-4 flex items-center">
              <FaClock className="text-gray-500 mr-2" />
              <div>
                <strong>Présence sur site:</strong>
                <div>Durée: {reservation?.onSiteDuration} h</div>
                <div>Tarif: {reservation?.onSiteFee ? `${reservation?.onSiteFee} CHF` : 'Non spécifié'}</div>
              </div>
            </div>
          )}

          {reservation?.dismantling && (
            <div className="mb-4 flex items-center">
              <FaTools className="text-gray-500 mr-2" />
              <strong>Démontage:</strong> {reservation?.dismantlingFee ? `${reservation?.dismantlingFee} CHF` : 'Non spécifié'}
            </div>
          )}

          {reservation?.addPickup && (
            <div className="mb-4">
              <div className="flex items-center">
                <FaBuilding className="text-gray-500 mr-2" />
                <div>
                  <strong>Reprise:</strong>
                  <div>Adresse: {reservation?.pickupAddress}</div>
                  <div>Tarif: {reservation?.pickupFee ? `${reservation?.pickupFee} CHF` : 'Non spécifié'}</div>
                </div>
              </div>
            </div>
          )}

          {reservation?.discount && (
            <div className="mb-4 flex items-center">
              <FaTag className="text-gray-500 mr-2" />
              <strong>Remise:</strong> {reservation?.discountAmount ? `${reservation?.discountAmount} CHF` : 'Non spécifié'}
            </div>
          )}
        </div>
        <br/>
        {/*Document */}
        <h2 className="text-xl font-bold my-4">{`4) Copie des contrats`}</h2>
        <div className="flex space-x-4">
          <DownloadButton link={reservation?.contractLink} label="Contrat" />
          <DownloadButton link={reservation?.productListLink} label="Liste du matériel" />
        </div>
        <br/>
        <br/>

        <h2 className="text-xl font-bold my-4">{`6) Note particulière`}</h2>
        <div className="mb-4">
          <label htmlFor="note" className="block text-gray-700 text-sm font-bold mb-2">
            Note
          </label>
          <textarea name="note" rows={3} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"></textarea>
        </div>
      </form>
      <br/>
      <DeleteMenu reservationStatus={reservation?.status} onStatusChange={handleStatusChange} />
    </div>
  );  
};

export default OngoingReservation;