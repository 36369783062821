import React from 'react';
import { FaDownload, FaBan } from 'react-icons/fa';

interface DownloadButtonProps {
    link?: string;
    label: string;
  }
  
  const DownloadButton: React.FC<DownloadButtonProps> = ({ link, label }) => {
    return (
      <div>
        {link ? (
          <a
            href={link}
            download
            className="inline-flex items-center bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            <FaDownload className="mr-2" /> {label}
          </a>
        ) : (
          <button
            className="inline-flex items-center bg-gray-500 text-white font-bold py-2 px-4 rounded cursor-not-allowed"
            disabled
          >
            <FaBan className="mr-2" /> Non disponible
          </button>
        )}
      </div>
    );
  };

  export default DownloadButton;