import React, { createContext, useContext, useState } from 'react';
import { Reservation } from '../reservations/Reservation';



const ReservationContext = createContext<{
  reservation: Reservation | null;
  setReservation: (reservation: Reservation | null) => void;
}>({
  reservation: null,
  setReservation: () => {},
});


export const ReservationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [reservation, setReservation] = useState<Reservation | null>(null);

    return (
        <ReservationContext.Provider value={{ reservation, setReservation }}>
            {children}
        </ReservationContext.Provider>
    );
};

// Hook personnalisé pour utiliser le contexte
export const useReservation = () => useContext(ReservationContext);
