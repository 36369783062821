import React, { useState, useEffect } from 'react';
import { useNavigate, } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import { db, functions } from '../components/auth/Config';
import { useAuth } from '../components/auth/AuthContext';
import { httpsCallable } from 'firebase/functions';
import { useLoading } from '../components/common/LoadingContext';
import { useReservation } from '../components/common/ReservationProvider';
import { Product } from '../components/common/ListBuilder';
import { convertDateToFrenchFormat, convertDateToNoTimezone } from '../components/common/Conversions';
import DownloadButton from '../components/common/DownloadButton';
import { FaUser, FaEnvelope, FaPhone, FaCommentDots} from 'react-icons/fa';
import DeleteMenu from '../components/common/DeleteMenu';
import { Reservation } from '../components/reservations/Reservation';
import { SpreadSheetResult } from '../components/common/SpreadSheet';

const OngoingReservation: React.FC = () => {
  const navigate = useNavigate();
  const { reservation } = useReservation();
  const { user, accessToken, setAccessToken } = useAuth();
  const [depositReturnMethod, setDepositReturnMethod] = useState<string>('');
  const [depositReturnAmount, setDepositReturnAmount] = useState<number>(0);
  const [returnDate, setReturnDate] = useState(convertDateToNoTimezone(new Date()).toISOString().substring(0, 16));
  const [reservationId, setReservationId] = useState<string>('');
  const [problem, setProblem] = useState<string>('');
  const [productList, setProductList] = useState<Product[]>([]);
  const [hasDispute, setHasDispute] = useState<boolean>(false);
  const [promotion, setPromotion] = useState<boolean>(false);
  const { setLoading } = useLoading();

  useEffect(() => {
    if (reservation) {
      if (reservation.depositReturnAmount) {
        setDepositReturnAmount(reservation.depositReturnAmount);
      } else if (reservation.depositAmount) {
        setDepositReturnAmount(reservation.depositAmount);
      }
      if (reservation.depositReturnMethod) {
        setDepositReturnMethod(reservation.depositReturnMethod);
      }
      setReservationId(reservation.id);
      if (reservation?.productList) {
        try {
          const parsedProductList: Product[] = JSON.parse(reservation.productList);
          setProductList(parsedProductList);
        } catch (error) {
          console.error("Parsing error:", error);
          setProductList([]);
        }
      }
    }
    
  }, [reservation]);



  // Helper function to create the reservation row, To be modified after better giveout form (with Role tracking)
  function createReservationRow(reservation: Reservation): string[] {
    const difference = (reservation.calculatedSum ?? 0) - reservation.productTotal;
    let diffString = '';
    if (difference != 0) {
      diffString = difference.toString();
    }

    return [
      reservation.reservationNumber.toString(), // #REF
      convertDateToFrenchFormat(reservation.pickupDate.substring(0, 10)),  // Date
      calculateDuration(reservation.pickupDate, reservation.returnDate), // Durée
      '',                                  // Payé ?
      'Nils',                                   // Encaissement
      reservation.productTotal.toString(),      // Total
      '',                                       // Ad 🚚
      diffString,                               // Nils 🚚
      reservation.paymentMethod || '',          // Moyen de paiement
      '',                                       // Description
      `${reservation.firstName} ${reservation.lastName}`, // Nom
      '',
    ];
  }

  // Helper function to calculate the duration
  function calculateDuration(pickupDate: string, returnDate: string): string {
    const start = new Date(pickupDate);
    const end = new Date(returnDate);
    const duration = Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
    return `${duration}`;
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

    if (!reservation) {
      alert("Un problème est survenu, réessayer");
      return;
    }
    e.preventDefault();
    if (!reservationId) {
      alert("L'id de la réservation est invalid, contacter l'administateur");
    }
    setLoading(true, "Enregistrement");
    const updateRef = doc(db, "reservations", reservationId);

    
    const formData = new FormData(e.currentTarget);
    const addToSheetChecked = formData.has('addToSheet');

    try {

      await updateDoc(updateRef, {
        depositReturnMethod: depositReturnMethod,
        depositReturnAmount: depositReturnAmount,
        returnNote: hasDispute ? problem : '',
        promotion: promotion,
        returnDate: returnDate,
        modifiedDate: new Date().toISOString(),
        status: "ok",
      })
      
    } catch (error) {
      setLoading(false);
      alert(error);
      return;
    }

    if (!addToSheetChecked) {
      setLoading(false);
      navigate('/');
      return;
    }

    
    setLoading(true, "Ajout à la comptabilité");

    // Add Row to Compta
    const row = createReservationRow(reservation);
    const addRowsToSheet = httpsCallable(functions, 'f0aa832e908bf455bd9d2ebcd72348d2d06c2ccc5a90793b581490b2195db9c');

    console.log(accessToken);
    
    try {
      const result = await addRowsToSheet({
        myToken: accessToken,
        values: [row], // values is expected to be an array of arrays
      });
      const data = result.data as SpreadSheetResult;
        if (data && data.accessToken) {
          setAccessToken(data.accessToken);
        }
    
          
      setLoading(false);
      navigate('/');
    } catch (error) {
      setLoading(false);
      console.error('Error adding row to Google Sheets:', error);
      alert('Error adding row to Google Sheets: \n\n' + error);
    }
  };

  const handleStatusChange = async (status: string) => {

    if (!reservation) {
      console.error("currentReservation est nulle");
      alert('Erreur de donnée, recharger la page');
      return;
    }

    setLoading(true, "Enregistrement");

    try {
      const reservationRef = doc(db, "reservations", reservation.id);
      await updateDoc(reservationRef, {
        status: status,
        modifiedDate: new Date().toISOString(),
      });
    } catch (error) {
      console.error('Erreur lors de l\'ajout de la réservation', error);
      alert('Erreur lors de l\'ajout de la réservation: \n\n' + error);
      setLoading(false);
    }

    setLoading(false);

    navigate('/');
  
  };

  return (
    <div className="container mx-auto p-4">

      <button
        onClick={() => navigate('/')}
        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded flex items-center"
      >
        <svg className="mr-2 w-4 h-4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
          <path d="M15 19l-7-7 7-7"/>
        </svg>
        Retour
      </button>

      <br/>
      <h1 className="text-xl font-bold my-4 flex justify-center">RETOUR</h1>
      <br/>

      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-xl font-bold mb-4">Réservation #{reservation?.reservationNumber}</h2>
        <div className="grid grid-cols-1 gap-4">
          <div className="flex items-center">
            <FaUser className="text-gray-500 mr-2" />
            <div>
              <strong>Prénom :</strong> <span>{reservation?.firstName}</span>
            </div>
          </div>
          <div className="flex items-center">
            <FaUser className="text-gray-500 mr-2" />
            <div>
              <strong>Nom :</strong> <span>{reservation?.lastName}</span>
            </div>
          </div>
          <div className="flex items-center">
            <FaEnvelope className="text-gray-500 mr-2" />
            <div>
              <strong>Email :</strong> <span>{reservation?.email}</span>
            </div>
          </div>
          <div className="flex items-center">
            <FaPhone className="text-gray-500 mr-2" />
            <div>
              <strong>Téléphone :</strong> <span>{reservation?.phoneNumber}</span>
            </div>
          </div>
          
          {reservation?.customerMessage && (
            <div className="flex items-start">
              <FaCommentDots className="text-gray-500 mt-1 mr-2" />
              <div>
                <strong>Message du client :</strong>
                <div className="mt-2">{reservation.customerMessage}</div>
              </div>
            </div>
          )}
          {reservation?.note && (
            <div className="flex items-start">
              <FaCommentDots className="text-gray-500 mt-1 mr-2" />
              <div>
                <strong>Note pour le jour J :</strong>
                <div className="mt-2">{reservation.note}</div>
              </div>
            </div>
          )}
          {reservation?.dDayNote && (
            <div className="flex items-start">
              <FaCommentDots className="text-gray-500 mt-1 mr-2" />
              <div>
                <strong>Note du jour J :</strong>
                <div className="mt-2">{reservation.dDayNote}</div>
              </div>
            </div>
          )}
        </div>
      </div>
      <br/>
      
      <form onSubmit={handleSubmit} className="mb-4">

        <h2 className="text-xl font-bold my-4">{`1) Retour du matériel`}</h2>

        <br/>
          {/*Planificateur de retour*/}
          <legend className="block text-gray-700 text-sm font-bold mb-2">
            Date et heure
          </legend>
          <input
            type="datetime-local"
            name="returnDateAndHour"
            required
            value={returnDate}
            onChange={(e) => setReturnDate(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />


        <br/>
        <br/>
        <br/>

        {/*Controler le materiel*/}
        <legend className="block text-gray-700 text-sm font-bold mb-2">
          Liste du matériel
        </legend>

        {productList.map((product, index) => (
          <div key={index} className="flex flex-wrap -mx-3 mb-4 items-center">
            <div className="w-full sm:w-7/12 px-3">
              <input
                type="text"
                value={product.title}
                disabled
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="w-4/12 sm:w-3/12 px-3">
              <input
                type="number"
                min="0"
                value={product.quantity}
                disabled
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
          </div>
        ))}
        
        <br/>
        <br/>

        <fieldset className="mb-4">
          <legend className="block text-gray-700 text-sm font-bold mb-2">
            Méthode de retour de la caution
          </legend>
          <div className="flex gap-4">
            <label>
              <input
                type="radio"
                name="depositReturnMethod"
                value="Twint"
                className='accent-purple-500'
                checked={depositReturnMethod === "Twint"}
                onChange={(e) => setDepositReturnMethod(e.target.value)}
                required
              />
              Twint
            </label>
            <label>
              <input
                type="radio"
                name="depositReturnMethod"
                value="Cash"
                className='accent-purple-500'
                checked={depositReturnMethod === "Cash"}
                onChange={(e) => setDepositReturnMethod(e.target.value)}
              />
              Cash
            </label>
            <label>
              <input
                type="radio"
                name="depositReturnMethod"
                value="no"
                className='accent-purple-500'
                checked={depositReturnMethod === "no"}
                onChange={(e) => setDepositReturnMethod(e.target.value)}
              />
              Sans caution
            </label>
          </div>
        </fieldset>

        <div className="mb-4">
          <input
            type="number"
            min="0"
            name="depositReturnAmount"
            id="depositReturnAmount"
            value={depositReturnAmount}
            onChange={(e) => setDepositReturnAmount(parseInt(e.target.value))}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Montant de la caution"
          />
        </div>
        <br/>
        <h2 className="text-xl font-bold my-4">{`2) Litige`}</h2>
        <legend className="block text-gray-700 text-sm font-bold mb-2">
            Y a-t-il eu un soucis avec la location ?
        </legend>
        <div>
          <label>
            <input
              type="checkbox"
              checked={hasDispute}
              className='accent-purple-500'
              onChange={(e) => setHasDispute(e.target.checked)}
            />
            Litige
          </label>
          <br/>
          <br/>
          {hasDispute && (
            <div className="mb-4">
              <label htmlFor="note" className="block text-gray-700 text-sm font-bold mb-2">
                Précisions
              </label>
              <textarea
                value={problem}
                onChange={(e) => setProblem(e.target.value)}
                style={{ opacity: hasDispute ? 1 : 0 }}
                name="problem"
                rows={3}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
              </textarea>
            </div>
          )}
        </div>
        {/*Document */}
        <h2 className="text-xl font-bold my-4">{`3) Copie des contrats`}</h2>
        <div className="flex space-x-4">
          <DownloadButton link={reservation?.contractLink} label="Contrat" />
          <DownloadButton link={reservation?.productListLink} label="Liste du matériel" />
        </div>
        <br/>
        <br/>

        {/*
        <h2 className="text-xl font-bold my-4">{`4) Avis`}</h2>
        <legend className="block text-gray-700 text-sm font-bold mb-2">
            Peut on envoyer des promotions personnalisées au client ?
        </legend>
        <div>
          <label>
            <input
              type="checkbox"
              checked={promotion}
              className='accent-purple-500'
              onChange={(e) => setPromotion(e.target.checked)}
            />
            Promotion
          </label>
        </div>
        <br/>
        <br/>
        */}
        
        <h2 className="text-xl font-bold my-4">{`4) Avis`}</h2>
        <div>
          <label>
            <input
              type="checkbox"
              required
              className='accent-purple-500'
            />
            Penser à suggérer un avis google
          </label>
        </div>
        <br/>
        <br/>
        <div className="flex justify-end">
          <div className="mb-4">
            <label>
              <input
                type="checkbox"
                defaultChecked
                className='accent-purple-500'
                name="addToSheet"
              />
              Ajouter à la comptabilité
            </label>
          </div>
        </div>
        <div className="flex justify-end">
          <button type="submit" className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">
            Valider
          </button>
        </div>
      </form>
      <br/>
      <DeleteMenu reservationStatus={reservation?.status} onStatusChange={handleStatusChange} />
    </div>
  );
};

export default OngoingReservation;