import React, { createContext, useContext, useState, ReactNode } from 'react';

interface LoadingContextType {
  loading: boolean;
  setLoading: (isLoading: boolean, message?: string) => void;
  loadingMessage: string | null;
  setLoadingMessage: (message: string | null) => void;
}

const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

export const useLoading = () => {
  const context = useContext(LoadingContext);
  if (context === undefined) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  return context;
};

export const LoadingProvider: React.FC<{children: ReactNode}> = ({ children }) => {
  const [loading, setLoadingState] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState<string | null>(null);

  const setLoading = (isLoading: boolean, message: string = "") => {
    setLoadingState(isLoading);
    setLoadingMessage(message ? message : null);
  };

  return (
    <LoadingContext.Provider value={{ loading, setLoading, loadingMessage, setLoadingMessage }}>
      {children}
      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          {/* Conteneur pour le loader et le message */}
          <div className="flex flex-col items-center">
            <div className="loader"></div> {/* Assurez-vous que votre loader est correctement stylisé */}
            {loadingMessage && <div className="text-white mt-2">{loadingMessage}...</div>}
          </div>
        </div>
      )}
    </LoadingContext.Provider>
  );
  
}