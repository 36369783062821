export interface Product {
  title: string;
  quantity: number;
  price: number;
}

export function extractProductList(lineItems: any): Product[] {
  return lineItems
      .filter((item: any) => !item.bundled_items || item.bundled_items.length === 0)
      .map((item: any) => ({
          title: item.name,
          quantity: item.quantity,
          price: 0
      }));
}
