import { Reservation } from "../reservations/Reservation";

export interface CalendarResultData {
  eventLink: string;
  accessToken: string;
}

export function createCalendarEvent(resa: Reservation): any {
  // Assuming productList is a JSON string, parse it first
  const products = JSON.parse(resa.productList);

  // Formatting the product list
  const formattedProductList = products.map((product: { title: string, quantity: number }) => 
    `${product.title} : ${product.quantity}`
  ).join("\n");

  // Adding "LIVRAISON" to the description if addDelivery is true
  let additionalInfo = "";
  let colorId = "3"; // Default color ID
  
  if (resa.addDelivery) {
    additionalInfo = "LIVRAISON\n";
    if (resa.deliveryAddress) {
      additionalInfo += `${resa.deliveryAddress}`;
    }
    colorId = "2"; // Set color ID to 11 for delivery
  }

  // Constructing the description with the required details
  const description = `Nom: ${resa.firstName} ${resa.lastName}\nEmail: ${resa.email}\nTél: ${resa.phoneNumber}\n\nMatériel:\n${formattedProductList}\n\n${additionalInfo}`;

  const startDateTime = new Date(resa.pickupDate).toISOString();
  const endDateTime = new Date(resa.returnDate).toISOString();

  console.log(`Start Date: ${startDateTime}, End Date: ${endDateTime}`);

  if (!startDateTime || !endDateTime) {
    console.error("Invalid start or end date.");
  }

  const calendarEvent = {
    summary: `LOC ${resa.lastName} ${resa.firstName} #${resa.reservationNumber.toString()}`,
    description: description,
    start: { dateTime: startDateTime },
    end: { dateTime: endDateTime },
    colorId: colorId
  };

  return calendarEvent;
}
