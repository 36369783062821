import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoMdCar } from 'react-icons/io';
import { FaBusinessTime, FaChevronDown, FaChevronUp, FaCoins, FaLocationArrow, FaMoneyBillWave } from 'react-icons/fa';
import { functions } from '../components/auth/Config';
import { httpsCallable } from 'firebase/functions';

function sumCosts(distance: number, costPerKm: number): number {
    let totalCost = 0;
    
    for (let k = 1; k <= 2 * distance; k++) {
      totalCost += costPerKm / Math.sqrt(1.5*k);
    }
  
    return totalCost;
}

const DeliveryCost: React.FC = () => {
    const navigate = useNavigate();

    const [expanded, setExpanded] = useState<boolean>(false);
    const [hourlyRate, setHourlyRate] = useState<number>(80);
    const [baseCostPerKm, setBaseCostPerKm] = useState<number>(2);
    const [distance, setDistance] = useState<number>(0);
    const [travelTime, setTravelTime] = useState<number>(0);
    const [installationTime, setInstallationTime] = useState<number>(0);
    const [address, setAddress] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const calculateCost = (): number => {
        const distanceCost = sumCosts(distance, baseCostPerKm);
        const travelCost = (travelTime / 60) * hourlyRate * 2;
        const installationCost = (installationTime / 60) * hourlyRate;
        const totalCost = travelCost + installationCost + distanceCost;
        return Math.floor(totalCost / 5) * 5;
    };

    const fetchDeliveryInfo = async () => {
        if (!address) return;
        
        setLoading(true); // Start loading spinner
        const getDeliveryCost = httpsCallable(functions, 'gdc');
        
        await getDeliveryCost({ origin: "Chemin du vallon 17, Bussigny 1030, Suisse", destination: address })
            .then((result: any) => {
                const data = result.data;
        
                if (data && data.error && data.error.includes('Failed to find a route')) {
                    alert('Aucune route trouvée. Veuillez vérifier l\'adresse ou la completer et réessayer.');
                    console.error('Delivery info:', data.error);
                    setLoading(false);
                    return;
                }
        
                console.log('Delivery info:', data);
                setDistance(Math.floor(data.distance / 1000));
                setTravelTime(Math.floor(data.duration / 60));
            })
            .catch((error) => {
                console.error('Error fetching delivery info:', error);
                const errorCode = error.code ? error.code : "No error code";
                const errorMessage = error.message ? error.message : "Unknown error occurred";
                const errorStack = error.stack ? error.stack : "No stack trace available";
                console.error('Error Details:', { code: errorCode, message: errorMessage, stack: errorStack });
                alert('Service indisponible pour le moment. Veuillez réessayer plus tard.');
            })
            .finally(() => {
                setLoading(false); // Stop loading spinner
            });
    }

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <div className="container mx-auto p-4">
            <button
                onClick={() => navigate('/')}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded flex items-center mb-4"
            >
                <svg className="mr-2 w-4 h-4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M15 19l-7-7 7-7" />
                </svg>
                Retour
            </button>
            
            <div className="p-4 sm:p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md flex flex-col space-y-4 mb-6">
                <div className="flex items-center justify-between cursor-pointer" onClick={toggleExpanded}>
                    <div className="flex items-center space-x-2">
                        <FaBusinessTime className="text-lg text-gray-800"/>
                        <h1 className="font-bold text-lg text-gray-800">Paramètres</h1>
                    </div>
                    {expanded ? <FaChevronUp className="text-lg text-gray-800"/> : <FaChevronDown className="text-lg text-gray-800"/>}
                </div>
                {expanded && (
                    <div>
                        <p className="text-sm text-gray-600 mb-4">
                            Ces paramètres sont utilisés pour calculer le coût de la livraison
                        </p>
                        <div className="flex flex-col space-y-3">
                            <div className="block">
                                <label className="block mb-1">Taux horaire (CHF) (Fixe):</label>
                                <input
                                    type="number"
                                    value={hourlyRate}
                                    onChange={(e) => setHourlyRate(parseFloat(e.target.value) || 0)}
                                    className="input input-bordered w-full max-w-xs p-2 rounded bg-gray-100"
                                />
                            </div>
                            <div className="block">
                                <label className="block mb-1">Coût de base par km (CHF) (Dégressif):</label>
                                <input
                                    type="number"
                                    value={baseCostPerKm}
                                    onChange={(e) => setBaseCostPerKm(parseFloat(e.target.value) || 0)}
                                    className="input input-bordered w-full max-w-xs p-2 rounded bg-gray-100"
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="p-4 sm:p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md flex flex-col space-y-4 mb-6">
                <div className="flex items-center space-x-2">
                    <FaLocationArrow className="text-lg text-gray-800"/>
                    <h1 className="font-bold text-lg text-gray-800">Fast Life</h1>
                </div>
                <p className="text-sm text-gray-600 mb-4">
                    Ce calcul utilise l'API Google Maps pour obtenir la distance et le temps de trajet en voiture
                </p>
                <input
                  type="text"
                  placeholder="Entrez l'adresse de livraison"
                  className="input input-bordered w-full max-w-xs p-2 rounded bg-gray-100 mb-4"
                  onChange={(e) => setAddress(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      fetchDeliveryInfo();
                    }
                  }}
                />

                <button
                    onClick={fetchDeliveryInfo}
                    className={`mx-auto bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded ${loading ? 'cursor-not-allowed' : ''}`}
                    disabled={loading}
                >
                    {loading ? (
                        <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zM12 20a8 8 0 010-16v4a4 4 0 100 8v4z"></path>
                        </svg>
                    ) : (
                        'Calculer'
                    )}
                </button>
            </div>
            <div className="p-4 sm:p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md flex flex-col space-y-4 mb-6">
                <div className="flex items-center space-x-2">
                    <IoMdCar className="text-lg text-gray-800"/>
                    <h1 className="font-bold text-lg text-gray-800">Livraison</h1>
                </div>
                <p className="text-sm text-gray-600 mb-4">
                    Indiquez la distance et le temps jusqu'au point de livraison
                </p>
                <div className="flex flex-col space-y-3">
                    <div className="block">
                        <label className="block mb-1">Distance (km):</label>
                        <input type="number" value={distance} onChange={(e) => setDistance(parseFloat(e.target.value))}
                            className="input input-bordered w-full max-w-xs p-2 rounded bg-gray-100" />
                    </div>
                    <div className="block">
                        <label className="block mb-1">Temps de parcours (min):</label>
                        <input type="number" value={travelTime} onChange={(e) => setTravelTime(parseFloat(e.target.value))}
                            className="input input-bordered w-full max-w-xs p-2 rounded bg-gray-100" />
                    </div>

                    <div className="block mt-2">
                        <label className="block mb-1">Temps d'installation (min):</label>
                        <input type="number" value={installationTime} onChange={(e) => setInstallationTime(parseFloat(e.target.value))}
                            className="input input-bordered w-full max-w-xs p-2 rounded bg-gray-100" />
                    </div>
                </div>
            </div>
            <div className="p-4 sm:p-6 max-w-lg mx-auto text-white bg-purple-700 rounded-xl shadow-md flex flex-col space-y-4">
                <div className="flex items-center space-x-2">
                    <FaMoneyBillWave className="text-lg"/>
                    <h1 className="font-bold text-lg">Suggestion tarifaire par aller-retour</h1>
                </div>
                <div className="flex justify-center font-bold text-lg">
                    {calculateCost().toFixed(2)} CHF
                </div>
                <div className="flex items-center space-x-2">
                    <FaMoneyBillWave className="text-lg"/>
                    <h1 className="text-lg">Equivalent horaire</h1>
                </div>
                <div className="flex justify-center text-lg">
                    {(calculateCost()/(travelTime + installationTime)*30).toFixed(2)} CHF
                </div>    
            </div>
        </div>
    );     
}

export default DeliveryCost;