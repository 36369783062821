import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { User, signOut, onAuthStateChanged, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from "./Config";
import { useLoading } from '../common/LoadingContext';


// Fournisseur Google
const provider = new GoogleAuthProvider();

interface AuthContextType {
  user: User | null;
  googleSignIn: () => void;
  logOut: () => void;
  accessToken: string;
  setAccessToken: (token: string) => void;
}

// Initialisation du contexte
const AuthContext = createContext<AuthContextType>({
  user: null,
  googleSignIn: () => {},
  logOut: () => {},
  accessToken: '',
  setAccessToken: (token: string) => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [accessToken, setAccessToken] = useState<string>('');
  const { setLoading } = useLoading();

  const googleSignIn = async () => {
    setLoading(true, "Connexion");
    try {
      provider.addScope('https://www.googleapis.com/auth/calendar');
      provider.addScope('https://www.googleapis.com/auth/spreadsheets');
      provider.setCustomParameters({
        'access_type': 'offline',
        'prompt': 'consent'
      });
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (credential && credential.accessToken) {
        setAccessToken(credential.accessToken);
      } else {
        console.log("Aucun accessToken disponible");
      }
      return result;
    } catch (error) {
      console.error("Erreur lors de la connexion avec Google", error);
      return null;
    } finally {
      setLoading(false);
    }
  };


  const logOut = async () => {
    setLoading(true, "Déconnexion"); // Débute le chargement
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error("Erreur lors de la déconnexion", error);
    } finally {
      setLoading(false); // Termine le chargement
    }
  };

  useEffect(() => {
    setLoading(true, "Connexion"); // Débute le chargement
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      setLoading(false); // Termine le chargement une fois l'utilisateur détecté ou non

    });
    return () => unsubscribe();
  }, []);

  return <AuthContext.Provider value={{ user, googleSignIn, logOut, accessToken, setAccessToken }}>{children}</AuthContext.Provider>;
};
