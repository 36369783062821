import React, { useEffect, useState } from 'react';
import { MdAutorenew } from 'react-icons/md';


interface DeleteMenuProps {
    reservationStatus?: string;
    onStatusChange: (status: string) => void; // La fonction à appeler pour changer le status de la réservation
}

const DeleteMenu: React.FC<DeleteMenuProps> = ({ reservationStatus, onStatusChange }) => {
    const [showSelector, setShowSelector] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');

    const translateStatus = (status: string) => {
        const statusMap: { [key: string]: string } = {
            draft: 'Brouillon',
            new: 'New',
            confirmed: 'Confirmée',
            out: 'Au client',
            ok: 'OK'
        };
        return statusMap[status] || 'Erreur';
    };

    const translateStatusBack = (status: string) => {
        const statusMap: { [key: string]: string } = {
            Brouillon: 'draft',
            New: 'new',
            Confirmée: 'confirmed',
            'Au client': 'out',
            OK: 'ok'
        };
        return statusMap[status] || 'err';
    };

    useEffect(() => {
        if (reservationStatus) {
            setSelectedStatus(translateStatus(reservationStatus));
        }
    }, [reservationStatus]);

    const handleDeleteClick = () => {
        if (window.confirm("Voulez-vous vraiment supprimer cette réservation ?")) {
            onStatusChange('deleted');
        }
    };

    const handleSubmit = async () => {
        if (selectedStatus === '🗑️') {
            setShowSelector(false);
            handleDeleteClick();
            return;
        }
        onStatusChange(translateStatusBack(selectedStatus));
    };

    const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedStatus(event.target.value);
    };

    const show = () => setShowSelector(true);
    const handleCancel = () => setShowSelector(false);

    return (
        <div>
          {showSelector && (
            <div className="fixed inset-0 flex items-center justify-center">
              <div className="bg-white p-4 rounded-lg shadow-lg max-w-sm w-full">
                <div className="flex flex-col items-center">
                  {['Brouillon', 'New', 'Confirmée', 'Au client', 'OK', 'Erreur', '🗑️'].map((status) => (
                    <label key={status} className="inline-flex items-center mt-2">
                      <input
                        type="radio"
                        name="status"
                        value={status}
                        defaultValue={reservationStatus}
                        onChange={handleStatusChange}
                        checked={selectedStatus === status}
                        className="form-radio"
                      />
                      <span className="ml-2">{status}</span>
                    </label>
                  ))}
                </div>
    
                <div className="flex justify-center mt-4 space-x-2">
                  <button className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700" onClick={handleCancel}>
                    Annuler
                  </button>
                  <button className="bg-purple-500 text-white py-2 px-4 rounded hover:bg-purple-700" onClick={handleSubmit}>
                    Valider
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="flex justify-center mt-4">
            <button className="bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-700 flex items-center" onClick={show}>
              <MdAutorenew className="mr-2" />
              Changer le status
            </button>
          </div>
        </div>
      );
};

export default DeleteMenu;
