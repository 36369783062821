import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../components/auth/Config';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useAuth } from '../components/auth/AuthContext';
import { Reservation } from '../components/reservations/Reservation';
import { useReservation } from '../components/common/ReservationProvider';
import { formatDateFromString } from '../components/common/Conversions';
import { MdRefresh } from 'react-icons/md';

const ReservationList: React.FC = () => {

  const navigate = useNavigate();
  const { user } = useAuth();
  const { setReservation } = useReservation();

  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [okReservations, setOkReservations] = useState<Reservation[]>([]);

  const setAndNavigate = (path: string, resa: Reservation) => {
    setReservation(resa);
    navigate(path);
  };

  const countReservationsByStatus = (status: string) => {
    if (status === "ok") return 0;
    return reservations.filter(reservation => reservation.status === status).length;
  };

const fetchResas = async () => {
  if (user) {
    const q = query(collection(db, "reservations"), where("userId", "==", user.uid.substring(0, 5)));
    const querySnapshot = await getDocs(q);
    let fetchedReservations = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as Reservation[];

    // Function to determine if the date is invalid
    const isDateInvalid = (dateString: string) => {
      const date = new Date(dateString);
      return isNaN(date.getTime()); // Invalid if not a date or if the date is in the past
    };

    // Sort reservations by invalid dates first, then by status and pickupDate
    const sortedReservations = fetchedReservations.sort((a, b) => {
      const aInvalid = isDateInvalid(a.pickupDate);
      const bInvalid = isDateInvalid(b.pickupDate);

      if (aInvalid && !bInvalid) return -1;
      if (!aInvalid && bInvalid) return 1;
      if (aInvalid && bInvalid) return 0;

      return new Date(a.pickupDate).getTime() - new Date(b.pickupDate).getTime();
    });

    const upcomingReservations = sortedReservations.filter(resa =>
      resa.status !== 'deleted' && resa.status !== 'ok'
    );
    const okSortedReservations = sortedReservations.filter(resa =>
      resa.status === 'ok'
    ).sort((a, b) => new Date(b.pickupDate).getTime() - new Date(a.pickupDate).getTime());

    setReservations(upcomingReservations);
    setOkReservations(okSortedReservations);
  }
};


  useEffect(() => {
    if (user) {
      fetchResas();
    }
  }, [user]);





  const handleReservationClick = (reservation: Reservation) => {
    if (reservation.status === 'new' || reservation.status === 'draft') {
      setAndNavigate(`/new/${reservation.id}`, reservation);
    } else if (reservation.status === 'confirmed') {
      setAndNavigate(`/give/${reservation.id}`, reservation);
    } else if (reservation.status === 'out') {
      setAndNavigate(`/collect/${reservation.id}`, reservation);
    } else if (reservation.status === 'ok') {
      setAndNavigate(`/resa/${reservation.id}`, reservation)
    } else {
      setAndNavigate(`/draft/${reservation.id}`, reservation);
    }
  };

  const statusColors = [
    { status: 'draft', color: 'bg-gray-500', name: 'Brouillon' },
    { status: 'new', color: 'bg-purple-500', name: 'New' },
    { status: 'confirmed', color: 'bg-pink-100', name: 'Confirmée' },
    { status: 'out', color: 'bg-orange-500', name: 'Au client' },
    { status: 'ok', color: 'bg-green-500', name: 'OK' },
    { status: 'default', color: 'bg-red-500', name: 'Erreur' },
  ];
  
  const getStatusIndicatorColor = (status: string) => {
    const statusColor = statusColors.find(item => item.status === status);
    return statusColor ? statusColor.color : 'bg-red-500'; // Fallback to 'bg-red-500' if not found
  };
  

  // Fonction pour formater la date en "Mois Année" (ex. : "Février 2024")
  const getMonthYear = (isoDateString: string | number | Date) => {
    const date = new Date(isoDateString);
    return date.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' });
  };
  
  // Fonction pour déterminer si un marqueur de mois doit être affiché
  const shouldShowMonthMarker = (currentIndex: number, reservations: Reservation[]) => {
    if (currentIndex === 0) return true;
    const currentMonthYear = getMonthYear(reservations[currentIndex].pickupDate);
    const previousMonthYear = getMonthYear(reservations[currentIndex - 1].pickupDate);
    return currentMonthYear !== previousMonthYear;
  };

  return (
    <div className="p-4">
      <div className="p-4">
        <h2 className="text-xl font-bold my-4">Gestion des Réservations</h2>
        <div className="mb-4">
          <button
            onClick={() => navigate('/create')}
            className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded mr-4 mb-2 sm:mb-0"  // Added responsive margin-bottom
          >
            Nouvelle Réservation
          </button>
          <button
            onClick={() => navigate('/deliveryCost')}
            className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded mr-4 mb-2 sm:mb-0"  // Added responsive margin-bottom
          >
            Frais de Livraison
          </button>
        </div>
      </div>

      <div className="mb-4 p-4 flex flex-col sm:flex-row justify-start items-center gap-4">
        <div className="text-lg font-semibold">Légende :</div>
        <div className="flex flex-wrap justify-center gap-2 sm:flex-nowrap">
          {statusColors.map((statusColor) => {
            const count = countReservationsByStatus(statusColor.status);
            return (
              <div key={statusColor.status} className="flex items-center gap-2">
                <div className={`w-3 h-3 rounded-full ${statusColor.color}`}></div>
                <span>{statusColor.name} {count > 0 ? `(${count})` : ''}</span>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex flex-wrap justify-start items-center gap-4">
        <h2 className="text-xl font-bold my-4 flex-shrink-0">Réservations à venir</h2>
        <button onClick={fetchResas} className="flex items-center justify-center p-2 rounded-full hover:bg-gray-200 focus:outline-none flex-shrink-0">
          <MdRefresh size="24" />
        </button>
      </div>

      {reservations.map((reservation, index) => (
        <React.Fragment key={reservation.id}>
          {shouldShowMonthMarker(index, reservations) && (
            <div className="text-base font-bold my-4">
              {getMonthYear(reservation.pickupDate)}
            </div>
          )}
          <div
            className="flex items-center p-4 my-2 shadow-lg rounded-lg hover:bg-gray-100 bg-white cursor-pointer user-select-none"
            onClick={() => { handleReservationClick(reservation); }}
          >
            <div className={`flex-shrink-0 w-3 h-3 rounded-full ${getStatusIndicatorColor(reservation.status)} mr-4`}></div>
            <div className="flex-grow">
              <div className="flex justify-between">
                <span className="font-bold">#{reservation.reservationNumber} - {reservation.firstName} {reservation.lastName}</span>
              </div>
                <div className="text-sm">
                  <p>{formatDateFromString(reservation.pickupDate)}</p>
                  {reservation && reservation.status !== 'draft' && (
                    <div>
                      <p>{formatDateFromString(reservation.returnDate)}</p>
                      <p>{reservation.email} | {reservation.phoneNumber}</p>
                    </div>
                  )}
                </div>

            </div>
          </div>
        </React.Fragment>
      ))}


      <br/>
      <br/>
      <br/>
      <div className="my-10">
        <h2 className="text-xl font-bold my-4">Réservations OK</h2>

        <br/>
        {okReservations.map((reservation, index) => (
          <React.Fragment key={reservation.id}>
            {shouldShowMonthMarker(index, okReservations) && (
              <div className="text-base font-bold my-4">
                {getMonthYear(reservation.pickupDate)}
              </div>
            )}
            <div
              className="flex items-center p-4 my-2 shadow-lg rounded-lg hover:bg-gray-100 bg-white cursor-pointer user-select-none"
              onClick={() => { handleReservationClick(reservation); }}
            >
              <div className={`flex-shrink-0 w-3 h-3 rounded-full ${getStatusIndicatorColor(reservation.status)} mr-4`}></div>
              <div className="flex-grow">
                <div className="flex justify-between">
                  <span className="font-bold">#{reservation.reservationNumber} - {reservation.firstName} {reservation.lastName}</span>
                </div>
                  <div className="text-sm">
                    <p>{formatDateFromString(reservation.pickupDate)}</p>
                    {reservation && reservation.status !== 'draft' && (
                      <div>
                        <p>{formatDateFromString(reservation.returnDate)}</p>
                        <p>{reservation.email} | {reservation.phoneNumber}</p>
                      </div>
                    )}
                  </div>

              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );  
};

export default ReservationList;