import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../components/auth/AuthContext';
import { FcGoogle } from 'react-icons/fc';

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const { user, googleSignIn } = useAuth();

  useEffect(() => {
    if (user) {
      navigate('/all', { replace: true });
      window.history.pushState(null, '', '/');
    }
  }, [user, navigate]);

  return (
    <div className="home-page flex justify-center items-center min-h-screen bg-customBackground" style={{ paddingBottom: 100 }}>
      {!user ? (
        <div className="login-container flex flex-col justify-center items-center p-4">
          <div>
            <img src="https://airsoundlocation.ch/wp-content/uploads/2023/04/AirSoundwhite-1.png" alt="Company Logo" className="logo mb-4" />
          </div>
          <button onClick={googleSignIn} className="bg-gray-800 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded flex items-center gap-2">
            <FcGoogle className="text-lg"/>Connexion avec Google
          </button>
        </div>
      ) : (
        <div className="text-center px-4">
          <p className="text-xl text-white font-bold">Connexion...</p>
        </div>
      )}
    </div>
  );
  
};

export default HomePage;
