import { Reservation } from "../reservations/Reservation";

export const generateConfirmationEmail = (
  reservation: Reservation,
  mailNote: string
): string => {
  const {
    firstName,
    lastName,
    pickupDate,
    depositAmount,
    calculatedSum,
    addDelivery,
    deliveryAddress,
    productList,
    depositMethod,
    paymentMethod,
  } = reservation;

  const rentalConditionsLink =
    "https://airsoundlocation.ch/conditions-generales/";
  const fullName = `${firstName} ${lastName}`;

  // Parsing pickup date and time
  const pickupDateTime = new Date(pickupDate);
  const formattedPickupDate = pickupDateTime.toLocaleDateString("fr-FR", {
    weekday: "long",
    day: "numeric",
    month: "long",
  });
  const formattedPickupTime = pickupDateTime.toLocaleTimeString("fr-FR", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const addressLink = "https://goo.gl/maps/iSfvB7PjJwK2Phj87";
  const violetColor = "#AF2CF1";

  // Generating the equipment list
  const equipmentList = JSON.parse(productList)
    .map(
      (item: { title: string; quantity: number }) =>
        `<li style="margin-bottom: 5px;">${item.title} (x${item.quantity})</li>`
    )
    .join("");

  const sectionTitle = addDelivery
    ? "Détails pour la livraison"
    : "Détails pour la prise en charge";

  // Check if mailNote is defined and not empty
  const mailNoteContent =
    mailNote && mailNote.trim() !== ""
      ? `
      <div style="background-color: #f0f0f0; padding: 15px; border-radius: 8px; margin-top: 20px; text-align: left;">
        <h3 style="color: ${violetColor}; display: flex; align-items: center;">
          <span style="margin-right: 10px;">📝</span> Note de réservation :
        </h3>
        <p>   ${mailNote}</p>
      </div>
    `
      : "";

  return `
    <div style="font-family: Arial, sans-serif; color: #333; line-height: 1.6; display: flex; justify-content: center; align-items: center; min-height: 100vh; background-color: #f9f9f9;">
      <div style="max-width: 600px; width: 100%; text-align: center; background-color: #fff; padding: 20px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); border-radius: 10px;">
        
        <div style="background-color: #000000; padding: 10px; border-radius: 8px; margin-bottom: 20px;">
          <img src="https://airsoundlocation.ch/wp-content/uploads/2023/04/AirSoundwhite-1.png" alt="AirSound Logo" style="max-width: 200px;">
        </div>

        <h2 style="color: ${violetColor};">Confirmation de Réservation</h2>
        
        <p>Bonjour <strong>${fullName}</strong>,</p>
        <p>Nous sommes heureux de vous confirmer la réservation du matériel pour le <strong>${formattedPickupDate}</strong>.</p>

        ${mailNoteContent}

        <div style="background-color: #f0f0f0; padding: 15px; border-radius: 8px; margin-top: 20px; text-align: left;">
          <h3 style="color: ${violetColor}; display: flex; align-items: center;">
            <span style="margin-right: 10px;">ℹ️</span> ${sectionTitle}
          </h3>
          <ul style="list-style-type: none; padding-left: 0; text-align: left;">
            <li style="display: flex; align-items: center; margin-bottom: 5px;">
              <span style="margin-right: 10px;">📍</span> ${
                addDelivery ? deliveryAddress : `<a href="${addressLink}" style="color: ${violetColor}; text-decoration: none;">Chemin du Vallon 17, Bussigny</a>`
              } 
            </li>
            <li style="display: flex; align-items: center;">
              <span style="margin-right: 10px;">⏰</span> ${
                addDelivery ? "Heure de livraison":"Heure de prise en charge"
              }&nbsp;<strong>${formattedPickupTime}</strong>
            </li>
          </ul>
        </div>

        <div style="background-color: #f0f0f0; padding: 15px; border-radius: 8px; margin-top: 20px; text-align: left;">
          <h3 style="color: ${violetColor}; display: flex; align-items: center;">
            <span style="margin-right: 10px;">📃</span> Pensez à prendre avec vous
          </h3>
          <ul style="list-style-type: none; padding-left: 0; text-align: left;">
            ${
              paymentMethod === "Facture" || (calculatedSum && calculatedSum <= 0)
                ? ``
                : `<li style="display: flex; align-items: center;"><span style="margin-right: 10px;">💵</span> <strong>${calculatedSum} CHF&nbsp;</strong> en cash ou Twint</li>`
            }
            ${
              depositMethod === "no" || (depositAmount && depositAmount <= 0)
                ? ``
                : `<li style="display: flex; align-items: center;"><span style="margin-right: 10px;">💵</span> <strong>${depositAmount} CHF&nbsp;</strong> en cash ou Twint pour la caution</li>`
            }
            <li style="display: flex; align-items: center;"><span style="margin-right: 10px;">🆔</span> Un document d'identité officiel</li>
          </ul>
        </div>

        <div style="background-color: #f0f0f0; padding: 15px; border-radius: 8px; margin-top: 20px; text-align: left;">
          <h3 style="color: ${violetColor}; display: flex; align-items: center;">
            <span style="margin-right: 10px;">📋</span> Matériel réservé
          </h3>
          <ul style="list-style-type: disc; padding-left: 20px; text-align: left;">
            ${equipmentList}
          </ul>
        </div>

        <p style="margin-top: 20px;">Veuillez prendre connaissance de nos <a href="${rentalConditionsLink}" style="color: ${violetColor}; text-decoration: none;">conditions générales de location</a>.</p>
        
        <div style="margin-top: 40px; text-align: center;">
          <p>Nous vous remercions pour votre confiance et restons à votre disposition pour toute question.</p>
          <p style="font-size: 14px; color: #999;">AirSound Location</p>
          <p style="font-size: 14px; color: #999;"><a href="https://airsoundlocation.ch/" style="color: ${violetColor}; text-decoration: none;">airsoundlocation.ch</a></p>
        </div>
      </div>
    </div>
  `;
};


export const generateContractEmail = (
  reservation: Reservation
): string => {

  const {
    firstName,
    lastName,
    pickupDate,
    returnDate,
    deliveryAddress,
    addPickup,
    pickupAddress,
    productListLink,
    contractLink,
  } = reservation;

  const fullName = `${firstName} ${lastName}`;
  const violetColor = "#AF2CF1";

  // Parsing pickup date
  const pickupDateTime = new Date(pickupDate);
  const formattedPickupDate = pickupDateTime.toLocaleDateString('fr-FR', {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  });

  const formattedPickupTime = pickupDateTime.toLocaleTimeString('fr-FR', {
    hour: '2-digit',
    minute: '2-digit',
  });

  // Parsing return date
  const returnDateTime = new Date(returnDate);
  const formattedReturnDate = returnDateTime.toLocaleDateString('fr-FR', {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  });

  const formattedReturnTime = returnDateTime.toLocaleTimeString('fr-FR', {
    hour: '2-digit',
    minute: '2-digit',
  });

  const returnSectionTitle = addPickup
    ? "Détails pour la récupération"
    : "Détails pour le retour";

  const returnSectionContent = addPickup
    ? `<li style="display: flex; align-items: center; margin-bottom: 5px; flex-wrap: wrap;">
         <span style="margin-right: 10px;">📍</span> Nous viendrons récupérer le matériel à ${pickupAddress || deliveryAddress || "l'adresse de prise en charge"} le ${formattedReturnDate} à ${formattedReturnTime}.
       </li>`
    : `<li style="display: flex; align-items: center; margin-bottom: 5px; flex-wrap: wrap;">
         <span style="margin-right: 10px;">📍</span> Vous vous engagez selons nos conditions à nous ramener le matériel en état pour le ${formattedReturnDate} à ${formattedReturnTime}.
       </li>`;

  return `
    <div style="font-family: Arial, sans-serif; color: #333; line-height: 1.6; display: flex; justify-content: center; align-items: center; min-height: 100vh; background-color: #f9f9f9;">
      <div style="max-width: 600px; width: 100%; text-align: center; background-color: #fff; padding: 20px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); border-radius: 10px;">
        
        <div style="background-color: #000000; padding: 10px; border-radius: 8px; margin-bottom: 20px;">
          <img src="https://airsoundlocation.ch/wp-content/uploads/2023/04/AirSoundwhite-1.png" alt="AirSound Logo" style="max-width: 200px;">
        </div>

        <h2 style="color: ${violetColor};">Confirmation de Contrat</h2>

        <p>Bonjour <strong>${fullName}</strong>,</p>
        <p>Le matériel de votre location vous a été remis le <strong>${formattedPickupDate}</strong> à <strong>${formattedPickupTime}</strong>.</p>

        <div style="background-color: #f0f0f0; padding: 15px; border-radius: 8px; margin-top: 20px; text-align: left;">
          <h3 style="color: ${violetColor}; display: flex; align-items: center;">
            <span style="margin-right: 10px;">📂</span> Documents Importants
          </h3>
          <ul style="list-style-type: none; padding-left: 0; text-align: left;">
            <li><a href="${productListLink}" style="color: ${violetColor}; text-decoration: none;">Télécharger la liste du matériel</a></li>
            <li><a href="${contractLink}" style="color: ${violetColor}; text-decoration: none;">Télécharger l'extrait des conditions générales</a></li>
          </ul>
        </div>

        <div style="background-color: #f0f0f0; padding: 15px; border-radius: 8px; margin-top: 20px; text-align: left;">
          <h3 style="color: ${violetColor}; display: flex; align-items: center;">
            <span style="margin-right: 10px;">🚚</span> ${returnSectionTitle}
          </h3>
          <ul style="list-style-type: none; padding-left: 0; text-align: left;">
            ${returnSectionContent}
          </ul>
        </div>

        <p style="margin-top: 20px;">Nous vous remercions pour votre confiance et restons à votre disposition pour toute question.</p>
        
        <div style="margin-top: 40px; text-align: center;">
          <p style="font-size: 14px; color: #999;">AirSound Location</p>
          <p style="font-size: 14px; color: #999;"><a href="https://airsoundlocation.ch/" style="color: ${violetColor}; text-decoration: none;">airsoundlocation.ch</a></p>
        </div>
      </div>
    </div>
  `;
};